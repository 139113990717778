import React from 'react';
import scrollTo from 'magic-tricks/lib/scrollTo';
import getDistanceFromTop from '../utils/getDistanceFromTop';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';

const onClickHash = (event, id) => {
	const elemHash = document.getElementById(id);
	if (!elemHash) return;
	event.preventDefault();
	scrollTo(getDistanceFromTop(elemHash) - 100); // header height
};

export default ({sections}) => (
	<div className="grid-container contained mt4 mb4 mt10--lg mb10--lg">
		<div className="row align--center">
			<div className="col c2 show--lg psy info-page__sidebar pt2">
				{sections.map(({_key, title}) => title?.trim() ? (
					<div key={_key} className="mb2 sans--xs">
						<a
							aria-label={title}
							onClick={event => onClickHash(event, _key)}
							className="sans--sm fw--800 link--opacity"
							href={`#${_key}`}>
							{title}
						</a>
					</div>
				):null)}
			</div>
			<div className="col c1 show--lg" />
			<div className="col c7--lg">
				{sections.map(({_key, title, copy}, index) => (
					<div className="mb4 info-box" key={_key} id={_key}>
						{title?.trim() && <h3 className="fw--800 tc tl--lg">{title}</h3>}
						<div className="line-break rich-text">
							<BlockContent
								blocks={copy}
								serializers={richText}
							/>
						</div>
					</div>
				))}
			</div>
		</div>
	</div>
);
